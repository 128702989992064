<template>
    <div class="ml-3 mb-2 rounded main-container">
        <div class="box-header">Password reset</div>
        <div class="box-body">
            <div class="box-content">
                <h1>Let's get you a new password</h1>
                <div class="box-content-body">
                    <p>
                    Fill in your E-mail and we'll send a password reset link to you in a flash!
                    </p>
                    <form>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">E-mail</label>
                            <input
                                type="text"
                                class="col-sm-4 form-control form-control-sm"
                                placeholder="email"
                                v-model="request.email"
                                required="true"
                            >
                            <small class="col-sm-4" v-if="errors.email">{{errors.email[0]}}</small>
                        </div>

                        <button
                            type="submit"
                            class="btn btn-dark btn-sm bg-dark mt-2"
                            @click.prevent="submitForgotPassword()"
                            v-text="loading ? 'Loading...' : 'Send'"
                            :disabled="loading"
                        ></button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AuthService from "@/services/AuthService"

export default {
    name: "register",
    data() {
        return {
            loading: false,
            errors: {},
            request: {
                email: null
            }
        }
    },
    mounted() {
    },
    methods: {
        submitForgotPassword() {
            this.loading = true;
            AuthService.forgotPassword(this.request).then(() => {
                this.errors = {};
            })
            .catch((error) => {
                this.errors = error.response.data;
            })
            .finally(() => this.loading = false);
        }
    }
}
</script>
